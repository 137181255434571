.ctct-form-wrapper .ctct-button {
  font-size: 0.9em;
}

.ctct-form-wrapper .ctct-message {
  border: 1px solid;
  padding: 1em;
}

.ctct-form-wrapper .error {
  background-color: rgba(255, 65, 54, 0.02);
  border-color: #FF4136;
  color: #cf0b00;
}

.ctct-form-wrapper .success {
  background-color: rgba(46, 204, 64, 0.02);
  border-color: #2ECC40;
  color: #1b7926;
}

.ctct-form-wrapper .ctct-form-field {
  margin: 0 0 1em;
}

.ctct-form-wrapper .ctct-field-inline {
  display: inline-block;
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-half {
    float: left;
    margin-right: 2%;
    width: 48%;
  }
  .ctct-form-wrapper .ctct-field-half:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-third {
    float: left;
    margin-right: 2%;
    width: 32%;
  }
  .ctct-form-wrapper .ctct-field-third:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-fourth {
    float: left;
    margin-right: 2%;
    width: 24%;
  }
  .ctct-form-wrapper .ctct-field-fourth:last-of-type {
    margin-right: 0;
  }
}

.ctct-form-wrapper input[type="text"]:required:valid, .ctct-form-wrapper input[type="email"]:required:valid, .ctct-form-wrapper input[type="password"]:required:valid, .ctct-form-wrapper input[type="tel"]:required:valid, .ctct-form-wrapper input[type="number"]:required:valid {
  background: white url("../images/check_circle.svg") no-repeat;
  background-position: 8px 50%;
  background-size: 24px;
  border-color: #2ECC40;
  padding-left: 40px;
}

.ctct-form-wrapper input[type="text"]:required.ctct-invalid, .ctct-form-wrapper input[type="text"].ctct-invalid, .ctct-form-wrapper input[type="email"]:required.ctct-invalid, .ctct-form-wrapper input[type="email"].ctct-invalid, .ctct-form-wrapper input[type="password"]:required.ctct-invalid, .ctct-form-wrapper input[type="password"].ctct-invalid, .ctct-form-wrapper input[type="tel"]:required.ctct-invalid, .ctct-form-wrapper input[type="tel"].ctct-invalid, .ctct-form-wrapper input[type="number"]:required.ctct-invalid, .ctct-form-wrapper input[type="number"].ctct-invalid {
  background: white url("../images/error.svg") no-repeat;
  background-color: rgba(255, 65, 54, 0.02);
  background-position: 8px 50%;
  background-size: 24px;
  border-color: #FF4136;
  padding-left: 40px;
}

.ctct-form-wrapper .ctct-field-error {
  font-size: 0.85em;
  font-style: italic;
}

.ctct-form-wrapper input.ctct-invalid {
  background: white url("../images/error.svg") no-repeat;
  background-color: rgba(255, 65, 54, 0.02);
  background-position: 8px 50%;
  background-size: 24px;
  border-color: #FF4136;
  padding-left: 40px;
}

.ctct-form-wrapper input.ctct-label-left,
.ctct-form-wrapper textarea.ctct-label-left {
  display: inline-block;
  width: 75%;
}

.ctct-form-wrapper span.ctct-label-left {
  display: inline-block;
  margin-right: 5%;
  width: 20%;
}

.ctct-form-wrapper input.ctct-label-right,
.ctct-form-wrapper textarea.ctct-label-right {
  display: inline-block;
  margin-right: 5%;
  width: 75%;
}

.ctct-form-wrapper span.ctct-label-right {
  display: inline-block;
  width: 20%;
}

.ctct-form-wrapper span.ctct-label-hidden {
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.ctct-form-wrapper #ctct_usage {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  border: 0px none;
  padding: 0px;
}

.ctct-form-wrapper #ctct-submitted:disabled {
  background-image: url("../images/oval.min.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  cursor: wait;
  opacity: 0.3;
}

.ctct-form-wrapper .ctct-form:after {
  clear: both;
  content: "";
  display: table;
}

.ctct-form-wrapper .ctct-form .ctct-label-top label,
.ctct-form-wrapper .ctct-form .ctct-label-bottom label {
  display: block;
}

.ctct-form-wrapper .ctct-form .ctct-label-left label,
.ctct-form-wrapper .ctct-form .ctct-label-right label {
  display: inline-block;
}

.ctct-form-wrapper .ctct-form abbr {
  border: none;
  color: #FF4136;
  font-size: 0.9em;
}

.ctct-form-wrapper .ctct-input-container label {
  color: #aaa;
  font-size: 0.8em;
}

.ctct-form-wrapper .ctct-field-error {
  color: #FF4136;
}

.ctct-form-wrapper .ctct-submit {
  cursor: pointer;
}

.ctct-twentyfourteen .ctct-form-field input {
  width: 100%;
}
